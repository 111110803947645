import {
	DiagramNavigationData,
	DiagramType,
	useDiagramService,
} from '@cb/product-react/Services/Diagram/DiagramService';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import {
	MetamodelDiagramTabs,
	ModelDiagramTabs,
	PipelineDiagramTabs,
	ProjectRoutes,
	VisualLibraryTabs,
	getAiLabLink,
} from '../../../Utils/NavigationUtils';

export default function NavigateToDiagram() {
	const { diagramId, centreOnId } = useParams<{ diagramId?: string; centreOnId?: string }>();

	const diagramService = useDiagramService();

	const [diagramNavigationData, setDiagramNavigationData] = useState<DiagramNavigationData>();

	useEffect(() => {
		if (!diagramId) return;
		let cancel = false;
		void diagramService.getDiagramNavigationData(diagramId).response.then((result) => {
			if (cancel) return;
			if (result) {
				setDiagramNavigationData(result);
			}
		});

		return () => {
			cancel = true;
		};
	}, [diagramId, diagramService]);

	const location = useLocation();
	// Parse query parameters
	const searchParams = new URLSearchParams(location.search);
	const version = searchParams.get('version');

	if (!diagramNavigationData) {
		return <LoadingSpinner fullPageOverlay text="Navigating to diagram" />;
	}

	const { entityId, organisationId, diagramType } = diagramNavigationData;
	const centreOnQueryParam = centreOnId ? `?centreOn=${centreOnId}` : '';

	let route = '';
	switch (diagramType) {
		case DiagramType.Model:
			route = `${getAiLabLink(organisationId, ProjectRoutes.Models)}/${entityId}/${version ?? 'latest'}/${ModelDiagramTabs.Diagram}${centreOnQueryParam}`;
			break;
		case DiagramType.Metamodel:
			route = `${getAiLabLink(organisationId, ProjectRoutes.Metamodels)}/${entityId}/${version ?? 'latest'}/${MetamodelDiagramTabs.Diagram}${centreOnQueryParam}`;
			break;
		case DiagramType.Pipeline:
			route = `${getAiLabLink(organisationId, ProjectRoutes.Pipelines)}/${entityId}/${version ?? 'latest'}/${PipelineDiagramTabs.Diagram}${centreOnQueryParam}`;
			break;
		case DiagramType.VisualLibrary:
			route = `${getAiLabLink(organisationId, ProjectRoutes.Visual)}/${entityId}/${version ?? 'latest'}/${VisualLibraryTabs.Diagram}${centreOnQueryParam}`;
			break;
		case DiagramType.MetamodelPreview:
			route = `${getAiLabLink(organisationId, ProjectRoutes.Metamodels)}/${entityId}/${version ?? 'latest'}/${MetamodelDiagramTabs.Preview}${centreOnQueryParam}`;
			break;
	}

	return <Redirect to={route} />;
}
