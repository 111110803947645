import React, { useEffect, useMemo, useState } from 'react';
import { BasicAccessToken, useAccessTokenService } from '@cb/product-react/Services/AccessTokenService';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import Codeblock from '@cb/solaris-react/Components/Content/Codeblock';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import InlineSpinner from '@cb/solaris-react/Components/Loading/InlineSpinner';
import { Variant } from '@cb/solaris-react/Constants/System';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';

export type RevealAccessTokenModalProps = PropsWithClassName<{
	accessToken: BasicAccessToken;
}>;

export default function RevealAccessTokenModal(props: RevealAccessTokenModalProps) {
	const { accessToken } = props;

	const accessTokenService = useAccessTokenService();
	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [revealedAccessToken, setRevealedAccessToken] = useState<string>();

	useEffect(() => {
		const loaderId = addLoader();
		accessTokenService
			.revealAccessToken(accessToken.id)
			.then((revealed) => {
				setRevealedAccessToken(revealed);
				removeLoader(loaderId);
			})
			.catch((err) => {
				ErrorUtils.handleError(err, 'Failed to reveal access token', () => {
					removeLoader(loaderId);
				});
			});
	}, [accessTokenService, accessToken.id, addLoader, removeLoader]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					ModalManager.hideModal();
				},
				variant: Variant.quiet,
				text: 'Close',
			},
		],
		[],
	);

	const handleCopyToClipboard = (didCopy: boolean) => {
		if (didCopy) {
			QuickToast.success('Copied to clipboard');
		} else {
			QuickToast.error('Failed to copy to clipboard');
		}
	};

	return (
		<ModalContent
			title={accessToken.description ?? ''}
			actions={actions}
			body={
				<div style={{ width: 'min(600px, 90vw)' }}>
					{isLoading ? (
						<InlineSpinner />
					) : (
						<div>
							<p>
								Your access token is as follows. <strong>Keep it safe!</strong>
							</p>
							<Codeblock onCopyToClipboard={handleCopyToClipboard} text={revealedAccessToken} />
							<p>To authenticate against API endpoints, set an HTTP header as follows:</p>
							<Codeblock
								onCopyToClipboard={handleCopyToClipboard}
								text={`Authorization: Bearer ${revealedAccessToken}`}
							/>
							<p>
								To use when running a pipeline locally, set an environment variable called&#32;
								<b>BOTPIPELINES_ACCESS_TOKEN</b> with the token as the value.
							</p>
							<p>PowerShell:</p>
							<Codeblock
								onCopyToClipboard={handleCopyToClipboard}
								text={`$env:BOTPIPELINES_ACCESS_TOKEN = "${revealedAccessToken}"`}
							/>
							<p>Bash:</p>
							<Codeblock
								onCopyToClipboard={handleCopyToClipboard}
								text={`export BOTPIPELINES_ACCESS_TOKEN="${revealedAccessToken}"`}
							/>
						</div>
					)}
				</div>
			}
		/>
	);
}
