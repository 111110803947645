import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface SnowOverlayProps {
	maxFlakes?: number;
	baseSize?: number;
	sizeVariance?: number;
	speed?: number;
	speedVariance?: number;
}

const SnowOverlay: React.FC = (props: SnowOverlayProps) => {
	const { maxFlakes = 200, baseSize = 5, sizeVariance = 5, speed = 10, speedVariance = 5 } = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const snowFlakes = useRef<HTMLDivElement[]>([]);
	const [pilePath, setPilePath] = useState('M0,100 V100 H100 V100 Z');

	useEffect(() => {
		const createSnowflake = () => {
			const container = containerRef.current;
			if (!container) return;

			if (snowFlakes.current.length > maxFlakes) return;

			const snowflake = document.createElement('div');
			snowflake.className = 'snowflake';
			const size = Math.random() * sizeVariance + baseSize; // Random size
			const duration = Math.random() * speedVariance + speed; // Random speed
			const left = Math.random() * window.innerWidth; // Random position

			snowflake.style.width = `${size}px`;
			snowflake.style.height = `${size}px`;
			snowflake.style.animationDuration = `${duration}s`;
			snowflake.style.left = `${left}px`;

			container.appendChild(snowflake);
			snowFlakes.current.push(snowflake);

			// Remove snowflake after animation ends
			setTimeout(() => {
				snowflake.remove();
				snowFlakes.current = snowFlakes.current.filter((flake) => flake !== snowflake);

				// Update the pile shape
				setPilePath((prevPath) => generateNewPilePath(prevPath));
			}, duration * 1000);
		};

		const interval = setInterval(createSnowflake, 200); // Create a new snowflake every 200ms

		return () => {
			clearInterval(interval);
		};
	}, [baseSize, maxFlakes, sizeVariance, speed, speedVariance]);

	return (
		<>
			<StyledDiv ref={containerRef}></StyledDiv>
			<StyledSvg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
				<path d={pilePath} fill="white" />
			</StyledSvg>
		</>
	);
};

export default SnowOverlay;

const StyledDiv = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	overflow: hidden;
	z-index: 9999;

	.snowflake {
		position: absolute;
		top: -10px;
		width: 10px;
		height: 10px;
		background: white;
		border-radius: 50%;
		opacity: 0.8;
		animation: fall linear infinite;
	}

	@keyframes fall {
		from {
			transform: translateY(-10vh);
			opacity: 0.8;
		}
		to {
			transform: translateY(110vh);
			opacity: 0.3;
		}
	}
`;

const StyledSvg = styled.svg`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 20%;
	pointer-events: none;
	z-index: 9998;

	path {
		transition: d 1s;
		color: rgba(255, 255, 255, 0.5);
		fill: rgba(255, 255, 255, 0.5);
	}
`;

const generateNewPilePath = (currentPath: string): string => {
	const width = 100; // SVG width in percentages
	const height = 100; // Max height of the pile (20% of the viewport)
	const numPoints = 60; // Number of points across the width for randomization

	// Parse the current path to extract points
	let currentHeights = Array(numPoints).fill(0) as number[]; // Default to flat if no currentPath is provided

	if (currentPath) {
		// capture the first Mx,y command
		const firstMatch = currentPath.match(/M(\d+),(\d+)/);
		const matches = currentPath.match(/L(\d+),(\d+)/g); // Extract "Lx,y" commands
		if (matches) {
			currentHeights = matches.map((match) => {
				const [, , y] = match.match(/L(\d+),(\d+)/)!;
				// @ts-expect-error - rematching is always going to be successful
				return 100 - parseFloat(y); // Convert from SVG space to height
			});
			if (firstMatch) {
				const [, , y] = firstMatch;
				// @ts-expect-error - rematching is always going to be successful
				currentHeights.unshift(100 - parseFloat(y));
			}
		}
	}

	// Adjust heights with the new snowflake
	const newHeights = currentHeights.map((h) => {
		const randomContribution = Math.random() + 0.03; // Randomize impact of snowflake
		return Math.min(h + randomContribution, height); // Limit to max height
	});

	// Generate the new path
	const newPath =
		newHeights
			.map((h, i) => {
				const x = Math.floor((i / (numPoints - 1)) * width); // Spread points evenly
				const y = Math.ceil(100 - h); // Convert height back to SVG space
				return `${i === 0 ? 'M' : 'L'}${x},${y}`;
			})
			.join(' ') + ' V100 H0 Z';

	return newPath;
};
